<template>
<div class="container-fluid">
    <div class="row flex-nowrap">
        <SideBar />
        <div id="app-page" class="col py-3">
            <div id="div-version" class="text-muted">
                v{{ $store.getters.appVersion }}
            </div>
            <router-view/>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import SideBar from '../components/SideBar.vue';
export default {
    components: {
        SideBar
    },
    methods: {
        ...mapActions('orders', ['loadWorkOrders', 'loadUserLastEvent', 'setLoading']),

    },

    async created() {
        this.setLoading(true)
        await this.loadWorkOrders()
        await this.loadUserLastEvent()
        this.setLoading(false)
    }
}
</script>


<style>
#div-version {
    position: absolute;
    right: 10px;
}
.bg-card {
    background-color: #f8f8f8;
}
</style>
