<template>
    <div class="d-flex">
        <div class="col">
            <robotman-navbar-1></robotman-navbar-1>
            <div id="app-page">
                <div class="container">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RobotmanNavbar1 from '../../shared/components/RobotmanNavbar1'; 

export default {
    components: {
        RobotmanNavbar1
    },
    setup() {
        
    },
}
</script>