<template>
    <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-light">
        <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <div class="h2 text-center my-3 mx-0 d-none d-sm-block">
                <img src="@/assets/robot-logo.png" class="img-fluid" alt="Robot">
                <div class="h2 app-text text-center"> Manufacturing</div>
            </div>
            </a>
            <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                <li>
                    <router-link :to="{ name: 'backoffice-products' }" class="nav-link align-middle">
                        <i class="fs-4 bi-grid"></i> <span class="ms-1 d-none d-sm-inline">Products</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'backoffice-orders' }" class="nav-link">
                        <i class="fs-4 bi-calendar-check"></i> <span class="ms-1 d-none d-sm-inline">Work orders</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'backoffice-registries' }" class="nav-link">
                        <i class="fs-4 bi-table"></i> <span class="ms-1 d-none d-sm-inline">Registers</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'backoffice-users' }" class="nav-link">
                        <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">Users</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'backoffice-notifications' }" class="nav-link">
                        <i class="fs-4 bi-bell"></i> <span class="ms-1 d-none d-sm-inline">Notifications</span>
                    </router-link>
                </li>
                <li>                        
                    <a href="#submenu2" data-bs-toggle="collapse" class="nav-link align-middle ">
                        <i class="fs-4 bi-file-text"></i> <span class="ms-1 d-none d-sm-inline">Informs</span>
                    </a>
                    <ul class="collapse nav flex-column ms-1" ref="dashboardCollapse" id="submenu2" data-bs-parent="#menu">
                        <li class="w-100">
                            <router-link :to="{ name: 'backoffice-dashboard-products' }" class="nav-link ms-4">
                                <span class="d-none d-sm-inline">Dashboard</span> Products
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'backoffice-dashboard-orders' }" class="nav-link ms-4">
                                <span class="d-none d-sm-inline">Dashboard</span> work orders
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'backoffice-dashboard-registries' }" class="nav-link ms-4">
                                <span class="d-none d-sm-inline">Dashboard</span> Registries
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <router-link :to="{ name: 'checkin' }" class="nav-link">
                        <i class="fs-4 bi-alarm"></i> <span class="ms-1 d-none d-sm-inline">Check in/out</span>
                    </router-link>
                </li>
            </ul>
            <hr>
            <div class="dropdown pb-4">
                <a href="#" class="d-flex align-items-center app-text text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img :src="'https://ui-avatars.com/api/?background=random&name=' + getUser.username" alt="hugenerd" width="30" height="30" class="rounded-circle">
                    <span class="d-none d-sm-inline mx-1">{{ getUser.username }}</span>
                </a>
                <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                    <!-- <li><a class="dropdown-item" href="#">New project...</a></li>
                    <li><a class="dropdown-item" href="#">Settings</a></li> -->
                    <li>
                        <router-link :to="{ name: 'profile-home' }" class="dropdown-item">
                            <i class="fa-solid fa-user"></i> Profile
                        </router-link>
                    </li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li>
                        <router-link :to="{ name: 'logout' }" class="dropdown-item">
                            <i class="bi bi-box-arrow-left"></i> Log out
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';
import { Collapse } from 'bootstrap'

export default {
    setup() {
        const store = useStore()
        const route = useRoute()

        const dashboardCollapse = ref(null)

        onMounted(() => {
            if (route.name.startsWith('backoffice-dashboard-')) {
                console.log(dashboardCollapse.value);
                new Collapse(dashboardCollapse.value).show()
            }
        })
        
        return {
            getUser: computed(()=> store.getters['users/getUser']),

            dashboardCollapse
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.nav-link {
    color: $main-color;
}
.nav-link:hover {
    color: $main-color-hover;
}
.nav-link.active {
    background-color: $main-color;
}
ul.nav-pills > li {
    width: 100%;
}
ul.nav-pills {
    width: 100%;
}
</style>
